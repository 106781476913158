import * as React from "react";
import { observer, inject } from "mobx-react";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	GridToolbarExportContainer,
	GridPrintExportMenuItem,
	gridFilteredSortedRowIdsSelector,
	gridVisibleColumnFieldsSelector,
	useGridApiContext,
} from "@mui/x-data-grid";
import { MenuItem } from "@mui/material";

import { trTR } from "@mui/x-data-grid/locales";
import { trTR as pickersTrTr } from "@mui/x-date-pickers/locales";
import { trTR as coreTrTr } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as XLSX from "xlsx";

import dayjs from "dayjs";
export const ReportComponent = inject("store")(
	observer((props) => {
		const meetingStatus = ["Bekleniyor", "Tamamlandı", "Tamamlanmadı", "İptal Edildi", "Ertelendi"];
		const theme = createTheme(
			{
				palette: {
					primary: { main: "#1976d2" },
				},
			},
			trTR,
			pickersTrTr,
			coreTrTr
		);

		const defaultColumns = [
			{
				field: "no",
				headerName: "ID",
				width: 60,
			},
			{
				field: "meeting",
				headerName: "Oturum",
				width: 180,
			},
			{
				field: "doctorName",
				headerName: "Oluşturan",
				width: 180,
			},
			{
				field: "name",
				headerName: "İsim",
				width: 180,
			},
			{
				field: "mobilePhone",
				headerName: "Telefon",
				width: 180,
			},
			{
				field: "startingDate",
				headerName: "Başlama Tarihi",
				width: 180,
			},
			{
				field: "status",
				headerName: "Durumu",
				width: 180,
			},
			{
				field: "joined",
				headerName: "Katıldı",
				width: 100,
			},
		];
		const columns = props.questions?.map((question) => {
			return {
				field: `${question.questionId}`,
				headerName: question.questionText,
				width: 180,
			};
		});
		const rows = props.participants?.map((participant, index) => {
			const meeting = props.meetings.find((m) => m.meetingId === participant.meetingId);
			const row = {
				id: index,
				no: participant.participantId,
				meeting: props.meetings.find((m) => m.meetingId === participant.meetingId)?.fullName,
				name: participant.name,
				mobilePhone: participant.mobilePhone,
				startingDate: participant.startingDate ? dayjs(new Date(participant.startingDate)).format("DD.MM.YYYY HH:mm") : "",
				doctorName: meeting.doctorName,
				status: meetingStatus[meeting.status],
			};

			props.responses?.forEach((response, i) => {
				if (response.participantId === participant.participantId) {
					row[`${response.questionId}`] = response.responseValue === "true" ? "Evet" : response.responseValue === "false" ? "Hayır" : response.responseValue;
				}
			});
			return row;
		});

		function CustomToolbar() {
			const apiRef = useGridApiContext();
			return (
				<GridToolbarContainer>
					<GridToolbarQuickFilter />
					<GridToolbarFilterButton />
					<GridToolbarColumnsButton />
					<GridToolbarDensitySelector />
					<GridToolbarExportContainer {...props}>
						<GridPrintExportMenuItem />
						<MenuItem
							onClick={() => {
								handleExport(apiRef);
							}}
						>
							Excel
						</MenuItem>
					</GridToolbarExportContainer>
				</GridToolbarContainer>
			);
		}

		const allColumns = [...defaultColumns, ...columns];

		function handleExport(apiRef) {
			const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
			const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
			const header = visibleColumnsField.map((field) => allColumns.find((column) => column.field === field).headerName);
			const rows = filteredSortedRowIds.map((id) => {
				const row = [];
				visibleColumnsField.forEach((field) => {
					row.push(apiRef.current.getCellParams(id, field).value);
				});
				return row;
			});
			const worksheet = XLSX.utils.aoa_to_sheet([header, ...rows], {
				origin: "A1",
			});

			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, props.fileName);
			XLSX.writeFile(workbook, props.fileName + ".xlsx", { compression: true });
		}

		return (
			<ThemeProvider theme={theme}>
				<DataGrid
					ref={props.refs}
					columns={allColumns}
					rows={rows}
					slots={{
						toolbar: CustomToolbar,
					}}
				/>
			</ThemeProvider>
		);
	})
);
