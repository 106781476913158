import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

export const PrintButton = (props) => {
	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<span className="print-document" onClick={handlePrint}>
			<div style={{ display: "none" }}>
				<div ref={componentRef}>{props.content}</div>
			</div>
			{props.children}
		</span>
	);

	// <PrintButton content={<span/>}>
	//     <Button>Print</Button>
	// </PrintButton>;
};
